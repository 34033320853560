import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/builds/dr-pam/ndc-institute/node_modules/@dr-pam/common-components/Components/Testimonials/Testimonials.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/dr-pam/ndc-institute/node_modules/next-image-export-optimizer/dist/ExportedImage.js");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/ndc-institute/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/ndc-institute/public/images/about-1.jpg");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/ndc-institute/public/images/about-2.jpg");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/ndc-institute/public/images/about-3.jpg");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/ndc-institute/public/images/about-4.jpg");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/ndc-institute/public/images/accredited-logos/ACRRM-CPD-2023.png");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/ndc-institute/public/images/accredited-logos/aus-college-midwives.png");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/ndc-institute/public/images/accredited-logos/CPD-provider_stacked.jpg");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/ndc-institute/public/images/ndc-institute_home-of_inverted_large.png");
